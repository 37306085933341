var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Project Name","label-for":"project-name","label-cols-md":"3","label-class":"required"}},[_c('validation-provider',{attrs:{"name":"project name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"project-name","value":_vm.detail.projectName,"state":errors.length > 0 ? false : null},model:{value:(_vm.detail.projectName),callback:function ($$v) {_vm.$set(_vm.detail, "projectName", $$v)},expression:"detail.projectName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Short Description","label-for":"short-description","label-cols-md":"3"}},[_c('b-form-textarea',{attrs:{"id":"short-description","rows":"3","value":_vm.detail.projectDescription},model:{value:(_vm.detail.projectDescription),callback:function ($$v) {_vm.$set(_vm.detail, "projectDescription", $$v)},expression:"detail.projectDescription"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Customer","label-for":"customer","label-cols-md":"3","label-class":"required"}},[_c('validation-provider',{attrs:{"name":"customer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","options":_vm.customerList,"placeholder":"Please select","state":errors.length > 0 ? false : null},model:{value:(_vm.detail.selectedCustomerName),callback:function ($$v) {_vm.$set(_vm.detail, "selectedCustomerName", $$v)},expression:"detail.selectedCustomerName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Partner","label-for":"partner","label-cols-md":"3","label-class":"required"}},[_c('validation-provider',{attrs:{"name":"partner","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","options":_vm.partnerList,"placeholder":"Please select","state":errors.length > 0 ? false : null},model:{value:(_vm.detail.selectedPartnerName),callback:function ($$v) {_vm.$set(_vm.detail, "selectedPartnerName", $$v)},expression:"detail.selectedPartnerName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Business Type","label-for":"business-type","label-cols-md":"3","label-class":"required"}},[_c('validation-provider',{attrs:{"name":"business type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","options":_vm.businessTypeList,"placeholder":"Please select","state":errors.length > 0 ? false : null},model:{value:(_vm.detail.selectedBusinessTypeName),callback:function ($$v) {_vm.$set(_vm.detail, "selectedBusinessTypeName", $$v)},expression:"detail.selectedBusinessTypeName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Project Start","label-for":"project-start","label-cols-md":"3","label-class":"required"}},[_c('validation-provider',{attrs:{"name":"project start","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"project-start","type":"month","local":"en","min":_vm.minProjectStart,"max":_vm.maxProjectStart,"value":_vm.detail.projectStart,"state":errors.length > 0 || _vm.handleProjectStart() ? false : null},model:{value:(_vm.detail.projectStart),callback:function ($$v) {_vm.$set(_vm.detail, "projectStart", $$v)},expression:"detail.projectStart"}}),_c('small',{staticClass:"text-danger",style:(_vm.handleProjectStart() ? 'display: none' : 'display: block')},[_vm._v(_vm._s(errors[0]))]),_c('small',{staticClass:"text-danger",style:(_vm.handleProjectStart() ? 'display: block' : 'display: none')},[_vm._v("Invalid year (Must be now +- 10 year)")])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Hashtags","label-for":"hashtags","label-cols-md":"3","label-class":"required"}},[_c('validation-provider',{attrs:{"name":"hashtags","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"text","options":_vm.hashtagsList,"placeholder":"Please select","state":errors.length > 0 ? false : null},model:{value:(_vm.detail.selectedHashtagsName),callback:function ($$v) {_vm.$set(_vm.detail, "selectedHashtagsName", $$v)},expression:"detail.selectedHashtagsName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Development Tool","label-for":"development-tool","label-cols-md":"3","label-class":"required"}},[_c('validation-provider',{attrs:{"name":"development tool","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"text","options":_vm.developmentToolsList,"placeholder":"Please select","state":errors.length > 0 ? false : null},model:{value:(_vm.detail.selectedDevelopmentToolName),callback:function ($$v) {_vm.$set(_vm.detail, "selectedDevelopmentToolName", $$v)},expression:"detail.selectedDevelopmentToolName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Programming Language","label-for":"programming-language","label-cols-md":"3","label-class":"required"}},[_c('validation-provider',{attrs:{"name":"programming language","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"text","options":_vm.programmingLanguageList,"placeholder":"Please select","state":errors.length > 0 ? false : null},model:{value:(_vm.detail.selectedProgrammingLanguageName),callback:function ($$v) {_vm.$set(_vm.detail, "selectedProgrammingLanguageName", $$v)},expression:"detail.selectedProgrammingLanguageName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Database","label-for":"database","label-cols-md":"3","label-class":"required"}},[_c('validation-provider',{attrs:{"name":"database","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"text","options":_vm.projectDatabaseList,"placeholder":"Please select","state":errors.length > 0 ? false : null},model:{value:(_vm.detail.selectedProjectDatabaseName),callback:function ($$v) {_vm.$set(_vm.detail, "selectedProjectDatabaseName", $$v)},expression:"detail.selectedProjectDatabaseName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Team Owner","label-for":"team-owner","label-cols-md":"3","label-class":"required"}},[_c('validation-provider',{attrs:{"name":"team owner","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","options":_vm.teamOwnerList,"placeholder":"Please select","state":errors.length > 0 ? false : null},model:{value:(_vm.detail.selectedTeamOwnerName),callback:function ($$v) {_vm.$set(_vm.detail, "selectedTeamOwnerName", $$v)},expression:"detail.selectedTeamOwnerName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Cover Picture","label-for":"cover-picture","label-cols-md":"3"}},[(this.projectId == 0)?_c('b-form-file',{attrs:{"id":"fileCoverPicture","accept":"image/*","type":"file"},model:{value:(_vm.detail.coverPicture),callback:function ($$v) {_vm.$set(_vm.detail, "coverPicture", $$v)},expression:"detail.coverPicture"}}):_c('b-form-file',{attrs:{"id":"fileCoverPicture","accept":"image/*","placeholder":_vm.detail.coverPicture == null
                  ? 'No file chosen'
                  : _vm.detail.coverPicture,"type":"file"},model:{value:(_vm.detail.coverPicture),callback:function ($$v) {_vm.$set(_vm.detail, "coverPicture", $$v)},expression:"detail.coverPicture"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Power Point File","label-for":"power-point-file","label-cols-md":"3","label-class":"required"}},[(this.projectId == 0)?_c('b-form-file',{attrs:{"id":"power-point-file","type":"file","state":_vm.handleRequiredPowerPointFile,"accept":".pptx"},on:{"change":_vm.handleFiles},model:{value:(_vm.detail.powerPointFile),callback:function ($$v) {_vm.$set(_vm.detail, "powerPointFile", $$v)},expression:"detail.powerPointFile"}}):_c('b-form-file',{attrs:{"id":"power-point-file","placeholder":_vm.detail.powerPointFile == null
                  ? 'No file chosen'
                  : _vm.detail.powerPointFile,"type":"file","state":_vm.handleRequiredPowerPointFile,"accept":".pptx"},on:{"change":_vm.handleFiles},model:{value:(_vm.detail.powerPointFile),callback:function ($$v) {_vm.$set(_vm.detail, "powerPointFile", $$v)},expression:"detail.powerPointFile"}}),_c('small',{staticClass:"text-danger",style:({
                display: _vm.requiredPowerPointFile,
                'margin-top': '3px',
              })},[_vm._v(" The power point file field is required ")])],1)],1),_c('b-col',{attrs:{"cols":"12 mb-2"}},[_c('b-form-checkbox',{staticClass:"offset-md-3",attrs:{"checked":_vm.detail.publish,"name":"publish","switch":"","inline":""},model:{value:(_vm.detail.publish),callback:function ($$v) {_vm.$set(_vm.detail, "publish", $$v)},expression:"detail.publish"}},[_vm._v(" Publish ")])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"offset-md-3",staticStyle:{"width":"180px"},attrs:{"variant":"success","type":"submit"}},[_vm._v(" Save ")]),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.rediractToProjectsPage()}}},[_vm._v(" Close ")])],1)],1),_c('b-modal',{ref:"modal-errorManageProject",attrs:{"ok-only":"","ok-variant":"warning","ok-title":"Close","modal-class":"modal-warning","centered":"","title":_vm.titleErrorManageProject}},[_c('b-card-text',[_vm._v(" "+_vm._s(this.errorMessageManageProject)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }