<template>
  <b-card>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Project Name"
              label-for="project-name"
              label-cols-md="3"
              label-class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="project name"
                rules="required"
              >
                <b-form-input
                  id="project-name"
                  v-model="detail.projectName"
                  :value="detail.projectName"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Short Description"
              label-for="short-description"
              label-cols-md="3"
            >
              <b-form-textarea
                id="short-description"
                v-model="detail.projectDescription"
                rows="3"
                :value="detail.projectDescription"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Customer"
              label-for="customer"
              label-cols-md="3"
              label-class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="customer"
                rules="required"
              >
                <v-select
                  v-model="detail.selectedCustomerName"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="customerList"
                  placeholder="Please select"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Partner"
              label-for="partner"
              label-cols-md="3"
              label-class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="partner"
                rules="required"
              >
                <v-select
                  v-model="detail.selectedPartnerName"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="partnerList"
                  placeholder="Please select"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Business Type"
              label-for="business-type"
              label-cols-md="3"
              label-class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="business type"
                rules="required"
              >
                <v-select
                  v-model="detail.selectedBusinessTypeName"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="businessTypeList"
                  placeholder="Please select"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Project Start"
              label-for="project-start"
              label-cols-md="3"
              label-class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="project start"
                rules="required"
              >
                <b-form-input
                  id="project-start"
                  v-model="detail.projectStart"
                  type="month"
                  local="en"
                  :min="minProjectStart"
                  :max="maxProjectStart"
                  :value="detail.projectStart"
                  :state="
                    errors.length > 0 || handleProjectStart() ? false : null
                  "
                />
                <small
                  class="text-danger"
                  :style="
                    handleProjectStart() ? 'display: none' : 'display: block'
                  "
                >{{ errors[0] }}</small>
                <small
                  class="text-danger"
                  :style="
                    handleProjectStart() ? 'display: block' : 'display: none'
                  "
                >Invalid year (Must be now +- 10 year)</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Hashtags"
              label-for="hashtags"
              label-cols-md="3"
              label-class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="hashtags"
                rules="required"
              >
                <v-select
                  v-model="detail.selectedHashtagsName"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  label="text"
                  :options="hashtagsList"
                  placeholder="Please select"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Development Tool"
              label-for="development-tool"
              label-cols-md="3"
              label-class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="development tool"
                rules="required"
              >
                <v-select
                  v-model="detail.selectedDevelopmentToolName"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  label="text"
                  :options="developmentToolsList"
                  placeholder="Please select"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Programming Language"
              label-for="programming-language"
              label-cols-md="3"
              label-class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="programming language"
                rules="required"
              >
                <v-select
                  v-model="detail.selectedProgrammingLanguageName"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  label="text"
                  :options="programmingLanguageList"
                  placeholder="Please select"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Database"
              label-for="database"
              label-cols-md="3"
              label-class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="database"
                rules="required"
              >
                <v-select
                  v-model="detail.selectedProjectDatabaseName"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  label="text"
                  :options="projectDatabaseList"
                  placeholder="Please select"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Team Owner"
              label-for="team-owner"
              label-cols-md="3"
              label-class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="team owner"
                rules="required"
              >
                <v-select
                  v-model="detail.selectedTeamOwnerName"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="teamOwnerList"
                  placeholder="Please select"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Cover Picture"
              label-for="cover-picture"
              label-cols-md="3"
            >
              <b-form-file
                v-if="this.projectId == 0"
                id="fileCoverPicture"
                v-model="detail.coverPicture"
                accept="image/*"
                type="file"
              />
              <b-form-file
                v-else
                id="fileCoverPicture"
                v-model="detail.coverPicture"
                accept="image/*"
                :placeholder="
                  detail.coverPicture == null
                    ? 'No file chosen'
                    : detail.coverPicture
                "
                type="file"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Power Point File"
              label-for="power-point-file"
              label-cols-md="3"
              label-class="required"
            >
              <b-form-file
                v-if="this.projectId == 0"
                id="power-point-file"
                v-model="detail.powerPointFile"
                type="file"
                :state="handleRequiredPowerPointFile"
                accept=".pptx"
                @change="handleFiles"
              />
              <b-form-file
                v-else
                id="power-point-file"
                v-model="detail.powerPointFile"
                :placeholder="
                  detail.powerPointFile == null
                    ? 'No file chosen'
                    : detail.powerPointFile
                "
                type="file"
                :state="handleRequiredPowerPointFile"
                accept=".pptx"
                @change="handleFiles"
              />
              <small
                class="text-danger"
                :style="{
                  display: requiredPowerPointFile,
                  'margin-top': '3px',
                }"
              >
                The power point file field is required
              </small>
            </b-form-group>
          </b-col>
          <b-col cols="12 mb-2">
            <b-form-checkbox
              v-model="detail.publish"
              :checked="detail.publish"
              name="publish"
              switch
              inline
              class="offset-md-3"
            >
              Publish
            </b-form-checkbox>
          </b-col>
          <b-col cols="12">
            <b-button
              class="offset-md-3"
              variant="success"
              style="width: 180px;"
              type="submit"
            >
              Save
            </b-button>
            <b-button
              class="ml-2"
              variant="outline-secondary"
              @click="rediractToProjectsPage()"
            >
              Close
            </b-button>
          </b-col>
        </b-row>
        <b-modal
          ref="modal-errorManageProject"
          ok-only
          ok-variant="warning"
          ok-title="Close"
          modal-class="modal-warning"
          centered
          :title="titleErrorManageProject"
        >
          <b-card-text>
            {{ this.errorMessageManageProject }}
          </b-card-text>
        </b-modal>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BModal,
  VBModal,
  BCardText,
  BFormFile,
  BFormCheckbox,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BModal,
    BCardText,
    BFormFile,
    BFormCheckbox,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      dir: 'ltr',
      detail: [],
      businessTypeList: [],
      customerList: [],
      partnerList: [],
      teamOwnerList: [],
      hashtagsList: [],
      developmentToolsList: [],
      programmingLanguageList: [],
      projectDatabaseList: [],
      projectId: 0,
      titleErrorManageProject: '',
      errorMessageManageProject: '',
      titleSuccessManageProject: '',
      required,
      requiredPowerPointFile: 'none',
      handleRequiredPowerPointFile: null,
      // isSubmitted: false,
      // isUse: true,
      lists: [],
      validateProjectStart: false,
      minProjectStart: '',
      maxProjectStart: '',
      projectId2: 0,
    }
  },
  created() {
    const currentdate = new Date()
    this.minProjectStart = `${currentdate.getFullYear() - 10}-01`
    this.maxProjectStart = `${currentdate.getFullYear() + 10}-01`
    this.projectId = this.$route.params.projectId
    this.getDetail(this.projectId)
    this.projectId2 = this.projectId
  },
  methods: {
    getDetail(id) {
      useJwt.getProjectById(id).then(response => {
        this.detail = response.data.result
        this.businessTypeList = response.data.businessTypeList
        this.customerList = response.data.customerList
        this.partnerList = response.data.partnerList
        this.teamOwnerList = response.data.teamOwnerList
        this.hashtagsList = response.data.hashtagsList
        this.developmentToolsList = response.data.developmentToolsList
        this.programmingLanguageList = response.data.programmingLanguageList
        this.projectDatabaseList = response.data.projectDatabaseList
      })
    },
    handleFiles(event) {
      const file = event.target.files[0]
      if (file === 'undefined' || file === null) {
        this.requiredPowerPointFile = 'block'
        this.handleRequiredPowerPointFile = false
      } else {
        this.requiredPowerPointFile = 'none'
        this.handleRequiredPowerPointFile = null
      }
    },
    handleProjectStart() {
      if (this.detail.projectStart !== undefined) {
        if (this.detail.projectStart.split('-')[0].length > 4) {
          this.validateProjectStart = true
        } else if (
          this.detail.projectStart.split('-')[0]
          > new Date().getFullYear() + 10
          || this.detail.projectStart.split('-')[0] < new Date().getFullYear() - 10
        ) {
          this.validateProjectStart = true
        } else {
          this.validateProjectStart = false
        }
      }
      return this.validateProjectStart
    },
    submitForm() {
      // this.isSubmitted = true
      // this.isUse = false
      if (this.detail.powerPointFile == null) {
        this.requiredPowerPointFile = 'block'
        this.handleRequiredPowerPointFile = false
      } else {
        this.requiredPowerPointFile = 'none'
        this.handleRequiredPowerPointFile = null
      }
      this.$refs.simpleRules.validate().then(success => {
        if (
          success
          && this.detail.powerPointFile != null
          && !this.validateProjectStart
        ) {
          const mapData = JSON.stringify({
            ProjectId: this.projectId,
            ProjectName: this.detail.projectName,
            ShortDescription: this.detail.projectDescription,
            Customer: this.detail.selectedCustomerName,
            Partner: this.detail.selectedPartnerName,
            BusinessType: this.detail.selectedBusinessTypeName,
            ProjectStart: this.detail.projectStart,
            Hashtags: this.detail.selectedHashtagsName,
            DevelopmentTools: this.detail.selectedDevelopmentToolName,
            ProgrammingLanguage: this.detail.selectedProgrammingLanguageName,
            Database: this.detail.selectedProjectDatabaseName,
            TeamOwner: this.detail.selectedTeamOwnerName,
            IsPublish: this.detail.publish,
            ManageBy: JSON.parse(localStorage.getItem('userData')).username,
          })
          const formData = new FormData()
          formData.append('data', mapData)
          formData.append('fileCoverPicture', this.detail.coverPicture)
          formData.append('filePowerPointFile', this.detail.powerPointFile)
          useJwt
            .manageProject(formData)
            .then(response => {
              if (response.data.duplicateProjectName !== true) {
                this.showToastManageProjectSuccess('success')
              } else {
                if (this.projectId === 0) {
                  this.titleErrorManageProject = 'Cannot create new project'
                } else {
                  this.titleErrorManageProject = 'Cannot edit project detail'
                }
                this.errorMessageManageProject = 'The project name already exists'
                this.$refs['modal-errorManageProject'].show()
              }
            })
            .catch(error => {
              // if error is break
              console.log(this.ProjectId2)
              if (this.projectId2 === 0) {
                this.titleErrorManageProject = 'Create new project failed'
              } else {
                this.titleErrorManageProject = 'Edit detail failed'
              }
              this.errorMessageManageProject = error
              this.$refs['modal-errorManageProject'].show()
            })
          // } else {
          //   const querrySelectAll = document.querySelectorAll('.vs__dropdown-toggle')
          //   querrySelectAll.forEach((value, index) => {
          //     if (document.getElementsByClassName('vs__selected-options')[index].getElementsByClassName('vs__selected').length === 0) {
          //       querrySelectAll[index].style.border = '1px solid #ea5455'
          //     }
          //   })
        }
      })
    },
    showToastManageProjectSuccess(variant = null) {
      if (this.projectId === 0) {
        this.titleSuccessManageProject = 'Create new project successfully'
      } else {
        this.titleSuccessManageProject = 'Edit detail successfully'
      }
      this.$bvToast.toast(this.titleSuccessManageProject, {
        title: 'Alert Message',
        variant,
        solid: true,
        autoHideDelay: 2000,
      })
      setTimeout(() => this.$router.push({ name: 'projects' }), 2500)
    },
    rediractToProjectsPage() {
      this.$router.push({ name: 'projects' })
    },
    // handleRequiredBorder(index) {
    //   if (this.isSubmitted) {
    //     const querrySelectAll = document.querySelectorAll('.vs__dropdown-toggle')
    //     if (document.getElementsByClassName('vs__selected-options')[index].getElementsByClassName('vs__selected').length === 0) {
    //       querrySelectAll[index].style.border = '1px solid #ea5455'
    //     } else {
    //       querrySelectAll[index].style.border = '1px solid #DAD8E0'
    //     }
    //   }
    // },
    // handleRequiredBorderBeforeSubmitted(isRequireBorder, index) {
    //   if (this.isUse) {
    //     const querrySelectAll = document.querySelectorAll('.vs__dropdown-toggle')
    //     if (isRequireBorder) {
    //       querrySelectAll[index].style.border = '1px solid #ea5455'
    //       this.lists.push(index)
    //     } else if (isRequireBorder === false && this.lists.length > 0) {
    //       this.lists.forEach((item, indexList) => {
    //         if (indexList === index) {
    //           querrySelectAll[index].style.border = '1px solid #DAD8E0'
    //         }
    //       })
    //     }
    //   }
    // },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
input::placeholder {
  color: #d2d1d7 !important;
}

label.required::after {
  content: ' *';
  font-size: 14px;
  font-weight: bold;
  color: #ea5455;
}
</style>
